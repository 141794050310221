import React from 'react';

import SignInWithPhoneForm from '../components/SignIn/SiginWithMobile';
import './signin.css';
import {Link} from 'gatsby';

export default () => (
	<div className="fullScreen">
        <div className="topBar">
            <div className="logoDiv">
                <Link to="/">
                    <span className="logo">Auto</span>
                    <span className="logo text-style-1">Save</span>
                </Link>
            </div>
        </div>
		<div className="signInScreen">
			<SignInWithPhoneForm type="verification" />
		</div>
	</div>
);
