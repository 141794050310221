import React, {useEffect} from 'react';
import {navigate} from 'gatsby';

import {Link} from 'gatsby';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import '../../../pages/signin.css';
import backButton from '../../../images/icons/back-button.svg';
import profileImage from '../../../images/icons/profile-image.svg';

class SignInWithMobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			countryCode: '+91',
			phoneNumber: '', 
			codeInput: '',
			error: null, 
			confirmResult: null
		};
		this.props = props;
	}

	handleChange = (event) => {
		this.setState({phoneNumber: event.target.value});
	};

	handleOTPChange = (event) => {
		this.setState({codeInput: event.target.value});
	};

	confirmCode = () => {
		const { codeInput, confirmResult } = this.state;
		const firebase = this.props.firebase;

		if (confirmResult && codeInput.length) {
			if(codeInput.length  != 6) {
				var Error = {
					message: "Check the OTP and enter it again."
				}
				this.setState({error: Error});
				event.preventDefault();
				return;
			}
			confirmResult.confirm(codeInput.toString())
			.then(function (result) {
				if(result.additionalUserInfo.isNewUser) {
					firebase.user(result.user.uid).set({
						phoneNumber: result.user.phoneNumber,
						roles: {},
					});
				}
				navigate(ROUTES.HOME);
			})
			.catch((error) => {
				console.log('FAILED:' + error);
				this.props.firebase.clearRecaptcha();
			});
		}
	};

	onSubmitForm = (event) => {
		if(this.state.phoneNumber.length < 10) {
			var Error = {
				message: "Enter a valid phone number."
			}
			this.setState({error: Error});
			event.preventDefault();
			return;
		}
		//TODO: check if this is the signup request.

		const phoneNumber = this.state.countryCode + this.state.phoneNumber;
		if(this.props.type == 'signin') {
			this.props.firebase
				.doSignInWithPhone(phoneNumber)
				.then(confirmResult => this.setState({ confirmResult: confirmResult }))
				.catch((error) => {
					console.log('FAILED:' + error);
					this.props.firebase.clearRecaptcha();
				})
		} else if(this.props.type == 'verification') {
			this.props.firebase
			.doLinkWithPhoneNumber(phoneNumber)
			.then(confirmResult => this.setState({ confirmResult: confirmResult }))
			.catch((error) => {
				console.log('FAILED:' + error);
				this.props.firebase.clearRecaptcha();
			})
		}
		event.preventDefault();
	};

	renderPhoneNumberInput() {
		const {error} = this.state;
		
		 return (
			<div>
				<div className="headGroup">
					{this.props.type == 'signin' ?
						(<p className="header headerWithoutBack">Welcome back</p>) :
						(<div>
							<img className="profileImage" src={profileImage} alt="profile image" />
							<p className="profileName">User name</p>
							<p className="headerWithBack fontStyle mobileVerificationText">
								Enter the mobile number for verification</p></div>)
		 			}
				</div>
				<div className="contentWrap mobileVerificationContent">
					{error && <p className="errorMessage">{error.message}</p>}
					<div className="textBox">
						<span className="countryCode">{this.state.countryCode} -</span>
						<form onSubmit={this.onSubmitForm}>
							<input
								className="phoneInputText"
								type="text"
								autoComplete="phoneNumber"
								name="phoneNumber"
								onChange={this.handleChange}
								value={this.state.phoneNumber}
								id="phoneNumber"
								autoFocus
								required
								placeholder="Enter your phone number"
							/>
							<button className="buttonSignin" type="submit" 
								id="sign-in-button">
								<p className="inButtonText">Generate OTP</p>
							</button>
						</form>
					</div>
				</div>
			</div>
		 );
	   }

	renderVerificationCodeInput() {
		const {error} = this.state;

		return(
			<div>
				<div className="headGroup otpHeader">
					<Link to="/signInWithMobile">
						<img className="backButton" src={backButton} 
						alt="back button" />
					</Link>
					<p className="headerWithBack normalHeaderText otpText">
						Enter the OTP sent to your registered mobile number
					</p>
				</div>
				<div className="contentWrap">
					{error && <p className="errorMessage">{error.message}</p>}
					<div className="textBox">
						<input
							className="phoneInputText"
							type="text"
							autoComplete="OTP"
							name="OTP"
							onChange={this.handleOTPChange}
							value={this.state.codeInput}
							id="otp"
							autoFocus
							required
							placeholder="OTP"
						/>
						<p className="resendOTP">Resend OTP?</p>
						<button className="buttonSignin" onClick={this.confirmCode}>
							<p className="inButtonText">Proceed</p>	
						</button>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { confirmResult } = this.state;
		return (
			<div>
				{!confirmResult && this.renderPhoneNumberInput()}
				{confirmResult && this.renderVerificationCodeInput()}
			</div>
		);
	}
}

const SignInMobileForm = withFirebase(SignInWithMobile);

export default SignInMobileForm;
